import { StaticImage } from "gatsby-plugin-image"
import * as React from "react"
import HomeLayout from "../components/homelayout"
import PolicyContent from "../components/policycontent"
import SEO from "../components/seo"

const PrivacyPolicyPage = () => {
  return (
    <div>
      <SEO pageTitle="Privacy Policy | APPERCASED" pageDescription="APPERCASED Privacy Policy">
          <HomeLayout>
              <PolicyContent/>
        </HomeLayout>
      </SEO>
    </div>
  )
}

export default PrivacyPolicyPage
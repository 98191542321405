import { AnchorLink } from 'gatsby-plugin-anchor-links'
import { StaticImage } from 'gatsby-plugin-image'
import * as React from 'react'
import * as styles from "./policycontent.module.css"

const PolicyContent = () => {
    return (
        <div>
            <div className={styles.policylogo}>
            <AnchorLink to="/">
                <StaticImage src="../images/sdk_logo_white.png" objectFit="contain" height={35} alt="APPERCASED logo" />
            </AnchorLink>
            </div>
            <div className={styles.policycontentsection}>
                
                <div className={styles.policycontent}>
                    <h1>Privacy Policy</h1>
                    <h2>General Information</h2>
                    <p>
                        Krzysztof Rózga Mobile Apps ("Krzysztof Rózga", "I") based in Dąbrówka, Poland (EU VAT registration no. PL 7792503185) is committed to protect your personal information. 
                        The purpose of this Privacy Policy is to inform you about company privacy practices.
                    </p>
                    <h2>Personal Information I Collect</h2>
                    <p>
                        I collect personal information only when this information is provided by you (like e-mails or letters from you).
                    </p>
                    <h2>How I Use Personal Information</h2>
                    <p>I use your Personal Information to:</p>
                    <ul>
                        <li>prepare personalized offer on your request</li>
                        <li>provide customer support</li>
                        <li>inform you about availability of new product features and software updates</li>
                    </ul>
                    <h2>How I Share Personal Information</h2>
                    <p>
                        I may disclose your personal information to law enforcement and/or governmental entities in case I am required by law to do so.
                        I will not sell, distribute, or lease your personal information to unrelated third parties unless I have your permission. If I ever sell my company, or participate in a merger or consolidation, and user data is one of the assets involved in the sale, your personal information will be part of this sale or transfer.
                    </p>
                    <h2>Retention of Personal Information</h2>
                    <p>
                        I will retain your personal information for as long as this information serve the purpose for which it was collected.
                    </p>
                    <h2>Managing Your Personal Information</h2>
                    <p>
                        You’re in control of the data you provide to me. You can delete your personal information by submitting a request via email at hello@appercased.com. Please note that deleting your data might restrict my ability to provide you service.
                    </p>
                    <h2>Children's Privacy</h2>
                    <p>
                        My website do not address anyone under the age of 13. I do not knowingly collect personally identifiable information from children under 13 years of age. In the case I discover that a child under 13 has provided me with personal information, I immediately delete this from my servers. If you are a parent or guardian and you are aware that your child has provided me with personal information, please contact me so that I will be able to do the necessary actions. Please note that we may need to verify your identity as a parent or guardian.
                    </p>
                    <h2>Data Security</h2>
                    <p>
                        I value your trust in providing us your Personal Information and I'm committed to use commercially acceptable means of protecting it. Please remember that no method of transmission over the internet, or method of electronic storage is 100% secure and reliable, and I cannot guarantee its absolute security.
                    </p>
                    <h2>Links to Other Sites</h2>
                    <p>
                        This website may contain links to other sites. If you follow third-party link, you will be directed to that site. Note that these external sites are not operated by me. Therefore, I strongly advise you to review the Privacy Policy of these websites.
                    </p>
                    <h2>Changes to This Privacy Policy</h2>
                    <p>
                        I may update our Privacy Policy from time to time. You are advised to check this page periodically for any changes. I will notify you of any changes by posting the new Privacy Policy on this page.
                    </p>
                    <h2>Contact Us</h2>
                    <p>
                        If you have any questions about my Privacy Policy, do not hesitate to contact me at hello@appercased.com.
                    </p>
                    <br/>
                    <p>This Privacy Policy was last updated on January 21, 2023.</p>
                </div>
            </div>
        </div>
    )
}

export default PolicyContent